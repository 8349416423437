.work-list {
  row-gap: 3rem;
}

@media screen and (min-width: 576px) {
  .image-box-content {
    left: 50%;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 540px;
    width: 100%;
    max-height: 360px;
    height: 100%;
  }
}

@media screen and (min-width: 678px) {
  .work-list {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
  }
}

@media screen and (min-width: 992px) {
  .work-list {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 3rem;
  }
  .image-box-content {
    max-width: 700px;
    max-height: 440px;
  }
}
/*  */
.work-list {
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.work-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.work-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.work-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.work-item:hover img {
  opacity: 0.7;
}

.work-item::before {
  content: "View Project";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.work-item:hover::before {
  opacity: 1;
}
