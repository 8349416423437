.services {
  padding: 6rem 2rem; /* ריווח פנימי יותר גדול */
  background-color: #f9f9f9;
  min-height: 110vh; /* גובה מינימלי יותר גדול */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.section-title {
  text-align: center;
  margin-bottom: 4rem;
}

.services-list {
  display: grid;
  gap: 2rem;
}

.services-item {
  background-color: #fff;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.services-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.services-item-img img {
  width: 80px;
}

.services-item-text h2 {
  font-size: 1.8rem;
  color: #333;
  margin-top: 1rem;
}

.services-item-text p {
  max-width: 100%;
  color: #555;
  margin-top: 0.5rem;
}

@media screen and (min-width: 768px) {
  .services-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .services-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* פופאפ */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.popup.active {
  opacity: 1;
  pointer-events: auto;
}

.popup-content {
  background-color: #fff;
  border-radius: 15px;
  padding: 4rem;
  max-width: 700px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  width: 90%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  transform: scale(0.8);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.popup.active .popup-content {
  transform: scale(1);
  opacity: 1;
}

.popup-content h2 {
  margin-top: 0;
  text-align: center;
  animation: slideIn 0.5s ease;
  margin-bottom: 1.5rem;
  font-size: 2.2rem;
}

.popup-content p {
  border-top: 2px solid #ddd;
  padding-top: 2rem;
  font-size: 1.8rem;
  line-height: 1.6;
  color: #2e2e2e;
  margin-bottom: 1rem;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-45px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .popup-content {
    width: 95%; /* התאמה למסכים קטנים */
    max-height: 80vh; /* גובה מרבי במובייל */
  }
}

.popup-close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.5rem;
  cursor: pointer;
}
