.footer .section-title {
  margin-bottom: 0rem;
}
.footer.section-p {
  padding: 4.5rem 0;
}
.footer-social-links {
  margin-top: 2rem;
}
.footer-social-links li {
  margin: 0 1rem;
}
.footer .reserved-text {
  margin-top: 2rem;
}

.instagram:hover {
  color: #7f35b2;
}
.facebook:hover {
  color: #1877f2;
}
.twitter:hover {
  color: #1da1f2;
}

.footer-social-links a {
  transition: all 0.3s ease;
}

.section-title h3 {
  /* color: wheat;  משפיע על כל הקוד */
}