.content {
  padding: 2em 1em 0;
}

.content-subhead {
  /* text-transform: uppercase; */
  color: #aaa;
  border-bottom: 1px solid #eee;
  /* padding: 0.4em 0; */
  font-size: 125%;
  font-weight: 500;
  letter-spacing: 0.05em;
}
.post {
  padding-bottom: 2em;
}

.post-title {
  font-size: 2em;
  color: #222;
  /* margin-bottom: 0em; */
  margin-top: 0.3em;
}

.post-description-single {
  /* font-family: 'Roboto', sans-serif; */
  font-family: "Open Sans", sans-serif;
  color: #444;
  line-height: 1.8em;
  padding-top: 14px;
}
.post-description p {
  margin-top: -15px;
  margin-bottom: 5px;
}
.post-description-single h3,
.post-description-single h2 {
  font-size: 22px;
  padding: 18px 0 5px 0;
}

.post-description-single img {
  padding: 10px 0;
}

.post-meta {
  color: #999;
  font-size: 90%;
  margin: 0;
}

.post-header img {
  width: 100%;
  height: 100%;
}

.blog-footer {
  padding: 1em 0;
}
.blog-footer a {
  color: #ccc;
  font-size: 80%;
}

.button {
  /* background-color: #4caf50;  */
  background-color: var(--blue-color);
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.a:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

@media (min-width: 48em) {
  .content {
    padding: 2em 3em 0;
    margin: 0 25% 0 25%;
  }

  .blog-footer {
    text-align: center;
  }
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* או גובה אחר שמתאים לעיצוב שלך */
}

.spinner {
  border: 16px solid #f3f3f3; /* צבע הבסיס של הספינר */
  border-top: 16px solid #3498db; /* צבע הספינר */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.contact-form-section {
  margin-top: 30px; 
  margin-bottom: 30px; 
}

.contact-form-section form {
  width: 90%; 
  /* max-width: 800px;  */
  margin: 0 auto; /* ממרכז את הטופס */
}

.contact-form-section h3 {
  margin-bottom: 30px
}