.whatsapp {
  width: 48px;
  position: fixed;
  bottom: 25px;
  left: 20px;
  font-size: 245%;
  color: #25D366
}

.whatsapp:hover{
  transform: rotate(360deg);
  transition: 330ms ease-in-out;
}

@media (max-width: 768px) {
  .whatsapp {
    width: 40px;
  bottom: 15px;

    
  }
}