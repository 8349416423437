.contact-form {
  /* max-width: 460px; */
  width: 45%;
}
.contact-form .form-control {
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--light-grey-color);
  border-radius: 35px;
  min-height: 66px;
  font-size: 22px;
  padding: 0 3rem;
  line-height: 66px;
  margin-bottom: 2.6rem;
  outline: none;
  font-family: inherit;
  font-weight: 200;
}
.contact-form textarea.form-control {
  resize: none;
}
.contact-form .submit-btn {
  width: 100%;
  height: 66px;
  border-radius: 35px;
  border: 2px solid var(--brown-blue);
  transition: var(--transition);
}
.map {
  width: 45%;
  height: 500px;
}
.contact-map-form {
  display: flex;
}

@media screen and (max-width: 678px) {
  .contact-map-form {
  }
}

@media screen and (max-width: 1175px) {
  .contact-map-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .map {
    width: 100%;
  }
  .contact-form {
    width: 90%;
  }
}

.submit-btn.loading {
  background-color: #007bff;
  cursor: not-allowed;
  border-color: #007bff;
  opacity: 0.7;
}

.submit-btn:disabled {
  cursor: not-allowed;
  background-color: #089a95;
  border-color: #089a95;
}
/* Contact.css */
.Toastify__toast--success {
  background: #4CAF50; /* צבע ירוק הצלחה */
}

.Toastify__toast--error {
  background: #F44336; /* צבע אדום שגיאה */
}
